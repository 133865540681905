import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

import { PATHS_DASHBOARD } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const OverviewVendorPage = lazy(() => import('src/pages/vendor/statistics'));

// PROJECTS
const AllProjectCardPage = lazy(() => import('src/pages/project/all-card'));
const DetailProjectCardPage = lazy(() => import('src/pages/project/details-card'));

// CAMPAIGN
const AllCampaignPage = lazy(() => import('src/pages/campaign/vendor-my-campaign'));
const AllCampaignCardPage = lazy(() => import('src/pages/campaign/all-card'));
const CampaignMilestonesPage = lazy(() => import('src/pages/campaign/milestones'));
const DetailCampaignCardPage = lazy(() => import('src/pages/campaign/details-card'));
const CampaignVendorRequestChat = lazy(() => import('src/pages/campaign/vendor-request-chat'));
const SendBackRequestCampaignPage = lazy(() => import('src/pages/campaign/send-back-requests'));
const AddSendBackRequestCampaignPage = lazy(
  () => import('src/pages/vendor/add-send-back-request-campaign')
);

// Vendor
const VendorAcceptedMilestoneProgeressPage = lazy(
  () => import('src/pages/vendor/accepted-milestone-progress')
);
const VendorMilestoneInvoicesPage = lazy(() => import('src/pages/vendor/milestone-invoices'));
const VendorPaymentHistoryProgressPage = lazy(
  () => import('src/pages/vendor/payment-history-progress')
);

// Settings
const SettingsAccountInfoPage = lazy(() => import('src/pages/settings/account-info'));

// Tickets
const AllTicketsPage = lazy(() => import('src/pages/ticket/all'));
const TicketDetailPage = lazy(() => import('src/pages/ticket/detail'));

// ----------------------------------------------------------------------

const identifiedPaths = PATHS_DASHBOARD('VENDOR');

// ----------------------------------------------------------------------

export const vendorRoutes = {
  path: 'vendor',
  element: (
    <AuthGuard>
      <RoleBasedGuard hasContent roles={['VENDOR']} sx={{ py: 10 }}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to="/vendor/dashboard/statistics" replace />, index: true },
    {
      path: 'dashboard',
      children: [
        { element: <Navigate to="/vendor/dashboard/statistics" replace />, index: true },
        {
          path: 'statistics',
          element: <OverviewVendorPage title="menu.dashboard" activeRole="roles.VENDOR" />,
        },
      ],
    },
    {
      path: 'project',
      children: [
        { element: <Navigate to="/vendor/project/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllProjectCardPage
              title="menu.project.all_project"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/vendor/project/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailProjectCardPage
                      title="menu.project.details"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'campaign',
      children: [
        { element: <Navigate to="/vendor/campaign/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllCampaignCardPage
              title="menu.campaign.all_campaign"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
        {
          path: 'my-campaigns',
          element: (
            <AllCampaignPage
              title="menu.campaign.my_campaigns"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
        {
          path: 'vendor-requests',
          children: [
            {
              path: 'chat/:id',
              element: (
                <CampaignVendorRequestChat
                  title="menu.campaign.detail_vendor_request"
                  activeRole="roles.VENDOR"
                  paths={identifiedPaths}
                />
              ),
            },
          ],
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/vendor/campaign/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailCampaignCardPage
                      title="menu.campaign.details"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'milestones',
                  children: [
                    {
                      path: '',
                      element: (
                        <CampaignMilestonesPage
                          title="section.admin.dashboard.label.milestones"
                          activeRole="roles.VENDOR"
                          paths={identifiedPaths}
                        />
                      ),
                    },
                    {
                      path: ':milestone_id',
                      children: [
                        { path: '', element: <>Milestone Detail</> },
                        {
                          path: 'all-progress',
                          element: (
                            <VendorAcceptedMilestoneProgeressPage
                              title="menu.vendors.milestone_progress"
                              activeRole="roles.VENDOR"
                              paths={identifiedPaths}
                            />
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'progresses',
                  element: (
                    <VendorAcceptedMilestoneProgeressPage
                      title="menu.vendors.milestone_progress"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'invoices',
                  element: (
                    <VendorMilestoneInvoicesPage
                      title="menu.vendors.milestone_invoices"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'payment-history-progress',
                  element: (
                    <VendorPaymentHistoryProgressPage
                      title="menu.vendors.milestone_invoices"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'add-send-back-request',
                  element: (
                    <AddSendBackRequestCampaignPage
                      title="menu.vendors.add_send_back_request_campaign"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
              ],
            },
            {
              path: 'chat/:id',
              element: (
                <CampaignVendorRequestChat
                  title="menu.campaign.detail_vendor_request"
                  activeRole="roles.VENDOR"
                  paths={identifiedPaths}
                />
              ),
            },
          ],
        },
        {
          path: 'send-back-requests',
          element: (
            <SendBackRequestCampaignPage
              title="menu.campaign.send_back_requests"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
      ],
    },
    {
      path: 'settings',
      children: [
        { element: <Navigate to="/vendor/settings/account-info" replace />, index: true },
        {
          path: 'account-info',
          element: (
            <SettingsAccountInfoPage
              title="menu.settings.account_info"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
      ],
    },
    {
      path: 'tickets',
      children: [
        { element: <Navigate to="/vendor/tickets/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllTicketsPage
              title="menu.tickets.all_ticket"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/vendor/tickets/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <TicketDetailPage
                  title="menu.tickets.detail_ticket"
                  activeRole="roles.VENDOR"
                  paths={identifiedPaths}
                />
              ),
            },
          ],
        },
      ],
    },
  ],
};
