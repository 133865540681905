export const en = {
  volunteer: {
    success_applied: 'Volunteer applied to task succeessfully!',
    success_applied_responded: 'Volunteer apply responded succeessfully!',
  },
  campaign_edit: {
    ask_changes: 'Your Changes Request Successfully Submitted!',
    respond_changes: 'Campaign Successfully Edited!',
  },
};
